const formatDate = (date) => date.toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' });

var urlParams = new URLSearchParams(window.location.search);

document.querySelectorAll('[data-confirm]').forEach((link) => {
  link.addEventListener('click', event => {
    if(!confirm(link.dataset.confirm)) { event.preventDefault() }
  });
})

document.addEventListener("DOMContentLoaded", function(event) {
  const datePickerEl = document.getElementById('dashboard_picker');
  const dashboardPicker = new Litepicker({
    element: datePickerEl,
    autoRefresh: true,
    singleMode: false,
    maxDate: new Date,
    startDate: urlParams.get('start') || new Date,
    endDate: urlParams.get('end') || new Date,
    format: 'DD.MM.YYYY',
    setup: (picker) => {
      picker.on('selected', (date1, date2) => {
        window.location = `${ window.location.href.split('?')[0] }?start=${ formatDate(date1) }&end=${ formatDate(date2) }`

      });
    },
    plugins: ['ranges']
  });

  // datePickerEl.addEventListener('input', (event) => {
  //   console.log(event);
  // });
});
